// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".modal_modal__UTOVg {\r\n  width: 100%;\r\n  height: 100vh;\r\n  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  align-items: center;\r\n  justify-content: center;\r\n  pointer-events: none;\r\n  display: none;\r\n  opacity: 0.5;\r\n  transition: 200ms;\r\n  -webkit-transition: 200ms;\r\n  -moz-transition: 200ms;\r\n  -ms-transition: 200ms;\r\n  -o-transition: 200ms;\r\n  z-index: -1;\r\n}\r\n\r\n.modal_active__AQPZJ {\r\n  display: flex;\r\n  z-index: 70;\r\n  opacity: 1;\r\n  pointer-events: all;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/common/Modal/modal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,yEAAyE;EACzE,eAAe;EACf,MAAM;EACN,OAAO;EACP,mBAAmB;EACnB,uBAAuB;EACvB,oBAAoB;EACpB,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,qBAAqB;EACrB,oBAAoB;EACpB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,WAAW;EACX,UAAU;EACV,mBAAmB;AACrB","sourcesContent":[".modal {\r\n  width: 100%;\r\n  height: 100vh;\r\n  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  align-items: center;\r\n  justify-content: center;\r\n  pointer-events: none;\r\n  display: none;\r\n  opacity: 0.5;\r\n  transition: 200ms;\r\n  -webkit-transition: 200ms;\r\n  -moz-transition: 200ms;\r\n  -ms-transition: 200ms;\r\n  -o-transition: 200ms;\r\n  z-index: -1;\r\n}\r\n\r\n.active {\r\n  display: flex;\r\n  z-index: 70;\r\n  opacity: 1;\r\n  pointer-events: all;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "modal_modal__UTOVg",
	"active": "modal_active__AQPZJ"
};
export default ___CSS_LOADER_EXPORT___;
