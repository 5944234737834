// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".formContent_form-content__UkXmt {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 23px 23px 27px 23px;\r\n  background-color: #ffffff;\r\n  border-radius: 12px;\r\n}\r\n\r\n.formContent_form-content__svg__2Db4W {\r\n  margin-bottom: 9px;\r\n}\r\n\r\n.formContent_form-content__title__Xk95\\+ {\r\n  font-family: \"Dewi\";\r\n  font-weight: 700;\r\n  font-size: 24px;\r\n  line-height: 1.2;\r\n  letter-spacing: -0.04em;\r\n  color: #121c46;\r\n  margin-bottom: 11px;\r\n}\r\n\r\n.formContent_form-content__text__\\+2nsN {\r\n  font-family: \"Inter Tight\";\r\n  font-weight: 500;\r\n  font-size: 14px;\r\n  line-height: 1.5;\r\n  text-align: center;\r\n  color: #121c46;\r\n  width: 238px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/common/FormContent/formContent.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,4BAA4B;EAC5B,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;EAC1B,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,YAAY;AACd","sourcesContent":[".form-content {\r\n  display: flex;\r\n  flex-direction: column;\r\n  align-items: center;\r\n  justify-content: center;\r\n  padding: 23px 23px 27px 23px;\r\n  background-color: #ffffff;\r\n  border-radius: 12px;\r\n}\r\n\r\n.form-content__svg {\r\n  margin-bottom: 9px;\r\n}\r\n\r\n.form-content__title {\r\n  font-family: \"Dewi\";\r\n  font-weight: 700;\r\n  font-size: 24px;\r\n  line-height: 1.2;\r\n  letter-spacing: -0.04em;\r\n  color: #121c46;\r\n  margin-bottom: 11px;\r\n}\r\n\r\n.form-content__text {\r\n  font-family: \"Inter Tight\";\r\n  font-weight: 500;\r\n  font-size: 14px;\r\n  line-height: 1.5;\r\n  text-align: center;\r\n  color: #121c46;\r\n  width: 238px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form-content": "formContent_form-content__UkXmt",
	"form-content__svg": "formContent_form-content__svg__2Db4W",
	"form-content__title": "formContent_form-content__title__Xk95+",
	"form-content__text": "formContent_form-content__text__+2nsN"
};
export default ___CSS_LOADER_EXPORT___;
